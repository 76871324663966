import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import Router, { useRouter } from "next/router";
import Image from "next/image";
import Typography from "../../../higher-order-components/typography-hoc";
import Button from "../../../higher-order-components/button-hoc";
import SideModal from "../../../higher-order-components/model-hoc/Model";
import Waveform from "../../../higher-order-components/wavesurfe/wave";
import {
  getCookie,
  getLocal,
  removeLocal,
  setLocal,
} from "../../../common/function/storage";
import { handlePostSQS } from "../../../redux-store/actions/albums";
import { unixTimeStamp } from "../../../common/function/function";
import { getCurWaveTime } from "../../../common/function/waveServices";

const Store = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const [progress, setProgress] = useState(0);
  const _TOKEN = getLocal("access_token");
  let parseDetails = getCookie("login_user") ? getCookie("login_user") : null;

  const router = useRouter();
  const _GET_AUDIO_TYPES = useSelector((e) => e?.albums?.audioTypes);
  const _CURRENT_PLAY_DATA = useSelector(
    (state) => state.common.currentPlayData
  );

  const _PLAYING_STATE = useSelector((state) => state.common.playingState);

  const _AUDIO_QUALITY_MODAL = useSelector(
    (state) => state.common.audioQualityModal
  );
  let data = {
    album_track_id: "277",
    track_id: "277",
    album_id: "47",
    uri_name: "hear-ye-all-you-rascals",
    duration: 191.896,
    audio_type_id: "5",
    owner_id: "1",
    track_type: 1,
    artist_id: "1",
    title: "Hear ye All You Rascals",
    list_price: 1.5,
    net_price: 1.5,
    discount: 0,
    audio_type: "Music",
    artist_name: "Jean-Baptiste",
    cover_image_thumbnail:
      "https://bb20.s3.us-east-2.amazonaws.com/cover/halfling-campfire/w-100-h-100.jpg",
    cover_image_small:
      "https://bb20.s3.us-east-2.amazonaws.com/cover/halfling-campfire/w-200-h-260.jpg",
    cover_image_large:
      "https://bb20.s3.us-east-2.amazonaws.com/cover/halfling-campfire/w-250-h-325.jpg",
    cover_image_1: "https://bb20.s3.us-east-2.amazonaws.com/cover/",
    free_to_stream: false,
    is_lock: false,
    is_purchased: false,
  };

  const handle = {
    playPauseTrack: async (data, i) => {
      const _WAVESURFER_OBJECT = store.getState()?.common?.waveSurferObject;
      let payload = {
        events: _PLAYING_STATE ? "play" : "pause",
        source: "store",
        track_id: data?.track_id,
        start: getCurWaveTime(_WAVESURFER_OBJECT),
        end: getCurWaveTime(_WAVESURFER_OBJECT),
        is_purchased: data?.is_purchased,
        is_subscription: _TOKEN ? true : false,
        event_timestamp: unixTimeStamp(),
      };
      if (
        _CURRENT_PLAY_DATA?.tracksData[_CURRENT_PLAY_DATA?.currentPlayingIndex]
          ?.track_id === data?.track_id
      ) {
        _WAVESURFER_OBJECT && _WAVESURFER_OBJECT?.playPause();
        dispatch(handlePostSQS(payload));
      } else {
        dispatch({
          type: "CURRENT_PLAY_DATA",
          payload: {
            isMultiplePlaying: false,
            currentPlayingIndex: 0,
            library: false,
            tracksData: [data],
          },
        });
      }
    },
    multiClick: (ele) => {
      if (
        _CURRENT_PLAY_DATA?.tracksData?.length === 0 &&
        _CURRENT_PLAY_DATA?.tracksData[_CURRENT_PLAY_DATA?.currentPlayingIndex]
          ?.track_id === "undefined"
      ) {
        handle.playPauseTrack(),
          dispatch({ type: "PLAYING_STATE", payload: false });
      } else {
        handle.playPauseTrack(ele),
          dispatch({
            type: "PLAYING_STATE",
            payload: !_PLAYING_STATE,
          });
      }
    },
    close: () => {
      dispatch({ type: "AUDIO_QUALITY_MODAL", payload: false });
      dispatch({ type: "WAVE_SURFER", payload: null });
      dispatch({
        type: "CURRENT_PLAY_DATA",
        payload: {
          isMultiplePlaying: false,
          currentPlayingIndex: 0,
          library: false,
          tracksData: [],
        },
      });
    },
    multiIcon: (data) => {
      if (
        (_CURRENT_PLAY_DATA?.tracksData?.length === 0 &&
          _CURRENT_PLAY_DATA?.tracksData[
            _CURRENT_PLAY_DATA?.currentPlayingIndex
          ]?.track_id === "undefined") ||
        _CURRENT_PLAY_DATA?.tracksData[_CURRENT_PLAY_DATA?.currentPlayingIndex]
          ?.track_id !== data?.track_id
      ) {
        return "icon-play-icon home-play-pushicon";
      } else if (
        _CURRENT_PLAY_DATA?.tracksData[_CURRENT_PLAY_DATA?.currentPlayingIndex]
          ?.track_id === data?.track_id &&
        !_PLAYING_STATE
      ) {
        return "icon-pause2 home-play-pushicon";
      } else {
        return "icon-play-icon home-play-pushicon";
      }
    },
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = _AUDIO_QUALITY_MODAL ? "hidden" : "auto";
    // disableScrolled(_AUDIO_QUALITY_MODAL);
  }, [_AUDIO_QUALITY_MODAL]);

  const openSoundBoard = () => {
    removeLocal("website");
    setLocal("website", "website");
    window.open(process.env.NEXT_PUBLIC_SOUNDBOARD, "_self");
  };

  const renderImage = (title) => {
    switch (title) {
      case "Event": {
        return "https://bb20.s3.us-east-2.amazonaws.com/cover/rite/w-200-h-260.jpg";
      }
      case "Music": {
        return "https://bb20.s3.us-east-2.amazonaws.com/cover/tavern-general/w-200-h-260.jpg";
      }
      case "Sound Effect": {
        return "https://bb20.s3.us-east-2.amazonaws.com/cover/spell-damage/w-200-h-260.jpg";
      }
      case "Soundscape": {
        return "https://bb20.s3.us-east-2.amazonaws.com/cover/weather/w-200-h-260.jpg";
      }
      case "Voiceover": {
        return "https://bb20.s3.us-east-2.amazonaws.com/cover/pain-injury/w-200-h-260.jpg";
      }
      default: {
        return null;
      }
    }
  };
  return (
    <React.Fragment>
      <React.Fragment>
        <section className="categories_section mob-pb-30" id="store">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="categories_list categories-list-box-div"
                  data-aos="zoom-in"
                  data-aos-easing="linear"
                  data-aos-duration="1200"
                >
                  {_GET_AUDIO_TYPES?.map((items, index) => (
                    <div
                      className={`cursor-pointer`}
                      onClick={async () => {
                        setProgress(progress + 80);
                        await dispatch({
                          type: "ALBUMS_TYPE",
                          payload: {
                            label: items?.audio_type,
                            value: items?.audio_type_id,
                          },
                        });
                        router.push("/store/albums");
                        removeLocal("routers");
                        setLocal("routers", "/store/albums");
                      }}
                      key={index}
                    >
                      <Image
                        src={renderImage(items?.audio_type)}
                        alt={items?.audio_type}
                        priority
                        height={150}
                        width={205}
                        className={
                          items?.audio_type === "Voiceover" ? "object_pos" : ""
                        }
                      />
                      <span
                        className={`font-weight-bold text-center categories-title-text`}
                      >
                        {items?.audio_type}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* who we are section start */}
        <section
          className="who-we-are-section mob-pt-30 mob-pb-30"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1300"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-9 mx-auto text-center">
                <div
                  data-aos="zoom-in"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                  className="who-we-are-logo"
                ></div>

                <Typography
                  variant={"h2"}
                  className={"font-weight-medium main-title m-t-15"}
                >
                  Who We Are
                </Typography>

                <Typography variant={"body1"} className={"fs-18"}>
                  We believe that moments experienced gaming with friends around
                  the physical or virtual table, can create lasting memories of
                  courage, grief, and exaltation. Those moments where the
                  collective imaginations of your group manifest into epic
                  tales, deserve audio worthy of every sizzling fireball,
                  darkest dungeon, and action packed battle to the death. With
                  the largest catalog of music, soundscapes, sound effects, and
                  voiceovers, all tailor made for tabletop games AND the tools
                  to use them, you'll have everything you need to orchestrate
                  games your group will talk about for years.
                </Typography>

                <Typography
                  variant={"h2"}
                  className={"fs-20 text-yellow-shadow"}
                >
                  We're gamers. We're BattleBards.
                </Typography>
                <Button
                  className={
                    "btn btn-outline-white m-t-30 com-border-btn border-width-2 cursor-pointer border-btn-hover mob-w-100 button-transition-effect"
                  }
                  icon={""}
                  position={""}
                  content={"More about Battlebards"}
                  // onClick={false}
                  onClick={() => {
                    removeLocal("active");
                    setLocal("active", "about-us");
                    Router.push("/about-us");
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
      <React.Fragment>
        <section
          className="who-we-offer-section who-we-offer-space-div mob-pt-30"
          id="tool"
        >
          <div className="container">
            <Typography
              variant={"h2"}
              className={"font-weight-medium main-title text-center m-t-20"}
            >
              What we offer
            </Typography>

            <div
              className="row p-t-100 m-b-100 column-reverse-div mob-top-who-we-offer mob-m-b-100"
              id="epic-audio"
            >
              <div
                className="col-sm-6 cursor-pointer"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1300"
                onClick={async () => {
                  dispatch({ type: "AUDIO_QUALITY_MODAL", payload: true });
                  await dispatch({
                    type: "CURRENT_PLAY_DATA",
                    payload: {
                      isMultiplePlaying: false,
                      currentPlayingIndex: 0,
                      tracksData: [data],
                      myLibrary: false,
                    },
                  });
                }}
              >
                <Typography
                  variant={"h3"}
                  className={"m-b-35 font-weight-normal sub-title"}
                >
                  Epic Audio
                </Typography>

                <Typography
                  variant={"body1"}
                  className={
                    "line-height-36 fs-18 color-white opacity-0-7 font-weight-normal"
                  }
                >
                  An ever-growing catalog of Music, Soundscapes, Sound Effects,
                  Voiceovers, and Event specific backdrops, BattleBards provides
                  gamers with the greatest treasure chest of audio ever
                  assembled, with the power of thousands of tracks able to fit
                  any adventure.
                </Typography>
              </div>
              <div className="col-sm-6">
                <div
                  className="who-we-offer-img epic-audio-img text-right"
                  data-aos="flip-left"
                  data-aos-easing="linear"
                  data-aos-duration="1200"
                >
                  <style jsx global>{`
                    .epicaudio {
                      border: 1px solid #dcba6c !important;
                    }
                  `}</style>
                </div>
              </div>
            </div>
            <div
              className="row p-t-100 p-b-100 mob-top-who-we-offer mob-m-b-100"
              id="soundboard-sec"
            >
              <div className="col-sm-6">
                <div
                  className="who-we-offer-img soundboard-img"
                  data-aos="flip-left"
                  data-aos-easing="linear"
                  data-aos-duration="1200"
                ></div>
              </div>
              <div className="col-sm-6">
                <div
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="1300"
                >
                  <Typography
                    variant={"h3"}
                    className={"m-b-35 font-weight-normal sub-title"}
                  >
                    Soundboard
                  </Typography>

                  <Typography
                    variant={"body1"}
                    className={
                      "line-height-36 fs-18 color-white opacity-0-7 font-weight-normal"
                    }
                  >
                    Our new Soundboard will allow game masters to orchestrate
                    their games quickly and easily. Designed with the feedback
                    from an army of seasoned game masters, we give you the power
                    to convey the drama, excitement, somber, and lighthearted
                    tone your story commands.
                  </Typography>

                  <button
                     onClick={ () => {
                      _TOKEN ? openSoundBoard() : Router.push("auth/signUp"), window.scrollTo(0, 0);;
                    }}
                    className="btn btn-outline-white com-border-btn border-width-2 border-btn-hover m-t-30 button-transition-effect"
                  >
                    Try it
                  </button>
                </div>
              </div>
            </div>
            <div
              className="row p-t-100 p-b-100 column-reverse-div mob-top-who-we-offer "
              id="casting-sec"
            >
              <div className="col-sm-6">
                <div
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="1300"
                >
                  <Typography
                    variant={"h3"}
                    className={"m-b-35 font-weight-normal sub-title"}
                  >
                    Cast
                  </Typography>
                  <Typography
                    variant={"body1"}
                    className={
                      "line-height-36 fs-18 color-white opacity-0-7 font-weight-normal"
                    }
                  >
                    Transmit the epic feel of your games to all of your remote
                    players in real time. Cast allows you to connect the
                    Soundboard with players joining from anywhere by doing
                    nothing more than sharing a link.
                  </Typography>
                  <button
                    className="btn btn-outline-white com-border-btn border-width-2 border-btn-hover m-t-30 button-transition-effect"
                    onClick={() => {
                      _TOKEN ? openSoundBoard() : Router.push("auth/signUp"), window.scrollTo(0, 0);;
                    }}
                  >
                    Try it
                  </button>
                </div>
              </div>
              <div className="col-sm-6">
                <div
                  className="who-we-offer-img cast-img"
                  data-aos="flip-left"
                  data-aos-easing="linear"
                  data-aos-duration="1300"
                ></div>
              </div>
            </div>
          </div>
        </section>
        {_AUDIO_QUALITY_MODAL && (
          <SideModal
            open={_AUDIO_QUALITY_MODAL}
            name={"epicAudio"}
            maxWidth={340}
            className="modal"
            onClose={() => handle.close()}
            header={<h4>Sample Audio</h4>}
            body={
              <div className="cursor-pointer">
                <Waveform flag={true} />
                <div
                  className="sampleAudio-playpause-div"
                  onClick={() => {
                    handle.multiClick(data);
                  }}
                >
                  <i className={handle.multiIcon(data)}></i>
                </div>
              </div>
            }
            footer={<React.Fragment> </React.Fragment>}
          />
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default Store;
