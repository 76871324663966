import React from "react";
import Store from "../../component/landing-page/store/store";

const store = ({ _GET_AUDIO_TYPES }) => (
  <div>
    <Store _GET_AUDIO_TYPES={_GET_AUDIO_TYPES} />
  </div>
);

export default store;
